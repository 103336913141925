import { render, staticRenderFns } from "./IpLibraryTable.vue?vue&type=template&id=8ee2dcba&scoped=true&"
import script from "./IpLibraryTable.vue?vue&type=script&lang=js&"
export * from "./IpLibraryTable.vue?vue&type=script&lang=js&"
import style0 from "./IpLibraryTable.vue?vue&type=style&index=0&id=8ee2dcba&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ee2dcba",
  null
  
)

export default component.exports